<template>
  <v-app class="seb-poc-app">
    <v-container fluid class="seb-poc-app__header ma-0 pa-0">
      <v-row no-gutters class="seb-poc-app__header">
        <v-col class="seb-poc-app__header__logo ma-0 pa-0">
          <n-img class="cursor-pointer" src="/seb-poc/logo.svg" width="56" height="56" @click="handleClickLogo" />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pb-0" fluid>
      <v-row align="center" class="position-relative">
        <v-col class="mx-2">
          <v-breadcrumbs v-if="breadcrumbItems?.length > 0" :items="breadcrumbItems" class="seb-poc-app__breadcrumbs">
            <template #title="{ item, index }">
              <div class="seb-poc-app__breadcrumbs__item">
                <span
                  class="seb-poc-app__breadcrumbs__item__text"
                  :class="{ 'seb-poc-app__breadcrumbs__item__text__in-active': index === breadcrumbItems.length - 1 }"
                >
                  {{ $t(item.title) }}
                </span>
              </div>
            </template>
            <template #divider>
              <v-icon icon="mdi-chevron-right" />
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col v-if="showPeriodPicker" class="position-absolute right-0 top-0" cols="3">
          <!-- ToDo: i18n -->
          <v-select
            v-model="selectedPeriodKey"
            label="Period"
            :items="periodOptions"
            item-title="label"
            item-value="key"
            variant="solo"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-main :class="$route.params.org_id">
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { nextTick } from "vue";
import { PoCThemes } from "@/themes";
import { setupAppConfiguration } from "@/utils/app-config";
import { useAppStore } from "~/store/app";
import { useDemoStore } from "~/store/demo";
import { PERIODS as SEB_PERIODS } from "~/constants/seb-poc";
import { storeToRefs } from "pinia";

const $route = useRoute();
const $router = useRouter();
const { $sentry, $setAppLocale } = useNuxtApp();
const vuetifyTheme = useTheme();

const themes = PoCThemes;

const { layoutSettings } = storeToRefs(useAppStore());
const { updateLayoutSettings } = useAppStore();
const { sebPeriod } = storeToRefs(useDemoStore());
const { setPeriod } = useDemoStore();

const periodOptions = Object.values(SEB_PERIODS).map((period) => ({
  key: period.key,
  label: period.label,
}));
const selectedPeriodKey = computed({
  get() {
    return sebPeriod.value.key;
  },
  set(key) {
    setPeriod(SEB_PERIODS[key]);
  },
});
const favicon = computed(() => {
  return layoutSettings.value && layoutSettings.value.favicon;
});
const title = computed(() => {
  return layoutSettings.value && layoutSettings.value.name;
});
const showPeriodPicker = computed(() => {
  return layoutSettings.value && layoutSettings.value.show_period_picker;
});
const clientId = computed(() => {
  return $route.query.user_id;
});
const accountId = computed(() => {
  return $route.query.account_id;
});
const breadcrumbs = computed(() => [
  {
    routeName: "product-demo-org_id-sp-investment-account",
    items: [
      {
        title: "product-demo.breadcrumbs.overview",
        disabled: false,
        href: `overview?user_id=${clientId.value}`,
      },
      {
        title: "product-demo.breadcrumbs.account-details",
        disabled: true,
      },
    ],
  },
  {
    routeName: "product-demo-org_id-sp-investment-position",
    items: [
      {
        title: "product-demo.breadcrumbs.overview",
        disabled: false,
        href: `overview?user_id=${clientId.value}`,
      },
      {
        title: "product-demo.breadcrumbs.account-details",
        disabled: false,
        href: `account?user_id=${clientId.value}&account_id=${accountId.value}`,
      },
      {
        title: "product-demo.breadcrumbs.position-details",
        disabled: true,
      },
    ],
  },
]);

const breadcrumbItems = computed(() => {
  return breadcrumbs.value.find((breadcrumb) => breadcrumb.routeName === $route?.name)?.items;
});

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: () => favicon.value,
    },
  ],
  titleTemplate: () => title.value,
});

watch(
  () => $route.fullPath,
  (newVal) => {
    $sentry.setContext({
      path: newVal.fullPath,
    });
    setup();
  },
);

setup();

function setup() {
  if ($route.params.org_id) {
    switch ($route.params.org_id) {
      case "seb-poc":
        _setupAppConfiguration(themes.SEB_POC, "SEB_POC");
        break;
      default:
        break;
    }
  }
  $sentry.setContext({
    path: $route.fullPath,
  });
}

async function _setupAppConfiguration(appConfig, _theme) {
  const configuration = setupAppConfiguration(appConfig);
  vuetifyTheme.global.name.value = _theme;

  await nextTick();

  if (configuration.store.locale) {
    $setAppLocale(configuration.store.locale);
  }
  updateLayoutSettings({
    ...configuration.store,
  });
}

function handleClickLogo() {
  $router.push({
    path: "/product-demo/seb-poc/sp/investment",
  });
}
</script>

<style lang="scss" scoped>
.seb-poc-app {
  font-family: "SEB SansSerif", sans-serif;
  &__header {
    height: 56px !important;
    background-color: rgb(var(--v-theme-primary));

    &__logo {
      flex-basis: 56px;
    }
  }

  &__breadcrumbs {
    :deep(a) {
      text-decoration: none !important;
    }
    :deep(.v-breadcrumbs-item--disabled) {
      opacity: 1 !important;
    }
    :deep(.v-breadcrumbs-divider) {
      padding: 0 !important;
    }

    &__item {
      &__text {
        color: rgb(var(--v-theme-breadcrumb)) !important;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
        &__in-active {
          color: rgb(var(--v-theme-primary)) !important;
        }
      }
    }
  }
}
</style>
